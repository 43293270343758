<script setup lang="ts">
import { isNumber } from '@v-c/utils'
import { isNull, isUndefined } from 'lodash-es'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import { createVNode } from 'vue'
import RootComponent from '../components/root-component.vue'
import type { CustomMenuData, CustomMenuDataItem } from '../components/menu-context'
import DeviceModal from './components/device-modal.vue'
import WallUpdate from './components/wall-update.vue'
import VideoDetailItem from './components/video-detail-item.vue'
import { translateToLocalTvWallDetail, translateToTvWallDetail } from './components/tools'
import { deleteTvWallApi, getTvWallApi, queryPublicTvWallsApi, updateTvWallApi } from '@/api/tv-wall'

const userStore = useUserStore()
const { userId } = storeToRefs(userStore)
const menuData = ref<CustomMenuData>([])
const openKeys = ref<(number | string)[]>([])
const selectedKeys = ref<(number | string)[]>([])
const tvWallDetail = ref<LocalTvWallDetail>({} as LocalTvWallDetail)
const deviceModalVisible = ref(false)
const wallUpdateModalVisible = ref(false)
const toUpdateWallDetail = ref<LocalTvWallDetail>({} as LocalTvWallDetail)
const videoIndex = ref()
const deviceIndex = ref()

const isDeployer = computed(() => userStore.isRole('DEPLOYER'))
const isManager = computed(() => userStore.isRole('MANAGER'))
const topPermission = computed(() => (isDeployer.value || isManager.value))
const selfEditPermission = computed(() => (userId.value === tvWallDetail.value.userId))

const permission = computed(() => (topPermission.value || selfEditPermission.value))

onMounted(async () => {
  await refreshMenu()
  await selectFirstWall()
})

async function refreshMenu() {
  const publicWalls = await queryPublicTvWallsApi()

  menuData.value = translateToMenuDatas(publicWalls)

  function translateToMenuDatas(walls: TvWall[]) {
    return walls.map(({ id, wallName, containerMax, userId }) => ({ id, title: wallName, containerMax, userId } as CustomMenuDataItem))
  }
}

async function onSelectedKeys(menuData: CustomMenuData) {
  selectedKeys.value = menuData.map(item => item.id)
  const wallId = menuData[0]?.id

  if (isUndefined(wallId) || isNull(wallId))
    return

  await setLocalWallDetail(wallId as number)
}

async function setLocalWallDetail(wallId: number) {
  const res = await getTvWallApi(wallId)

  tvWallDetail.value = translateToLocalTvWallDetail(res)
}

async function selectFirstWall() {
  if (menuData.value.length === 0)
    return

  const wallId = menuData.value[0].id
  selectWall(wallId as number)
}

function onOpenKeys() {}

function onAddTvWall() {
  toUpdateWallDetail.value = {} as any
  wallUpdateModalVisible.value = true
}

function onUpdateTvWall() {
  toUpdateWallDetail.value = tvWallDetail.value
  wallUpdateModalVisible.value = true
}

function onDeleteConfirm() {
  Modal.confirm({
    title: '确定删除此电视墙？',
    icon: createVNode(ExclamationCircleOutlined),
    okText: '确认',
    cancelText: '取消',
    async onOk() {
      await deleteTvWallApi(tvWallDetail.value.tvWallId)
      message.success(`删除成功`)
      const currentNodeIndex = menuData.value.findIndex(menu => menu.id === tvWallDetail.value.tvWallId)
      if (currentNodeIndex === -1)
        return
      chooseOtherNode(currentNodeIndex)
      await refreshMenu()
    },
  })
}

async function chooseOtherNode(curNodeIndex: number) {
  if (hasNextNode(curNodeIndex)) {
    chooseNode(curNodeIndex + 1)
  }
  else if (hasPreNode(curNodeIndex)) {
    chooseNode(curNodeIndex - 1)
  }
  else {
    emptyNode()
  }

  function hasNextNode(curNodeIndex: number) {
    return curNodeIndex + 1 < menuData.value.length
  }

  function hasPreNode(curNodeIndex: number) {
    return curNodeIndex - 1 >= 0
  }

  async function chooseNode(nodeIndex: number) {
    const node = menuData.value[nodeIndex]
    await setLocalWallDetail(node.id as number)
    selectedKeys.value = [node.id]
    openKeys.value = [node.id]
  }

  function emptyNode() {
    tvWallDetail.value = {} as LocalTvWallDetail
  }
}

function onAddDevice(index: number) {
  deviceIndex.value = index
  videoIndex.value = undefined
  deviceModalVisible.value = true
}
function onEditDevice(dataItem: TvVideo, vIndex: number) {
  deviceIndex.value = dataItem.containerIndex
  videoIndex.value = vIndex
  deviceModalVisible.value = true
}

async function onDeleteDevice(wallIndex: number, videoIndex: number) {
  tvWallDetail.value.videos[wallIndex].splice(videoIndex, 1)
  const res = await updateTvWallApi(translateToTvWallDetail(tvWallDetail.value))
  message.success('删除成功')
  await setLocalWallDetail(res)
}

async function onDeviceModalConfirm(video: TvVideo) {
  tvWallDetail.value.videos[deviceIndex.value][videoIndex.value] = video
  videoIndex.value = undefined
  deviceIndex.value = undefined
  await setLocalWallDetail(tvWallDetail.value.tvWallId)
}

async function onTvWallModalConfirm(wallId: number) {
  await refreshMenu()
  selectWall(wallId)
}

async function selectWall(wallId: number) {
  await setLocalWallDetail(wallId as number)
  selectedKeys.value = [wallId]
}
</script>

<template>
  <page-container>
    <content-header title="视频管理" />
    <flex-content :need-padding="false">
      <RootComponent
        :menu-data="menuData"
        :open-keys="openKeys"
        :selected-keys="selectedKeys"
        @update:selected-keys="onSelectedKeys"
        @update:open-keys="onOpenKeys"
      >
        <template #operation>
          <div px-12px py-10px flex justify-between style="border-bottom:1px solid rgb(230, 230, 230);background: var(--bg-color);">
            <div flex justify-start items-center>
              <span font-600 text-16px>{{ tvWallDetail?.wallName ?? "" }}</span>
            </div>
            <div flex justify-end>
              <a-space>
                <a-button type="primary" @click="onAddTvWall">
                  新增电视墙
                </a-button>
                <a-button v-if="permission && isNumber(tvWallDetail.tvWallId)" type="primary" @click="onUpdateTvWall">
                  修改电视墙
                </a-button>
                <a-button v-if="permission && isNumber(tvWallDetail.tvWallId)" type="primary" danger @click="onDeleteConfirm">
                  删除电视墙
                </a-button>
              </a-space>
            </div>
          </div>
        </template>

        <GridScreen v-if="tvWallDetail" :container-max="tvWallDetail.containerMax">
          <template #default="{ index }">
            <div w-full h-full flex flex-col overflow-hidden p-5px>
              <div v-if="tvWallDetail.videos && tvWallDetail.videos[index] && tvWallDetail.videos[index].length > 0" flex-1 overflow-hidden>
                <GridList :data="tvWallDetail.videos[index]">
                  <template #default="{ dataItem, index: gridIndex }">
                    <VideoDetailItem :data="dataItem" :permission="permission" @on-edit="onEditDevice(dataItem, gridIndex)" @on-delete="onDeleteDevice(index, gridIndex)" />
                  </template>
                </GridList>
              </div>
              <div v-else flex-1 />
              <a-button v-if="permission" type="dashed" mt-5px @click="onAddDevice(index)">
                + 添加监控设备
              </a-button>
            </div>
          </template>
        </GridScreen>
      </RootComponent>
    </flex-content>

    <DeviceModal v-model:open="deviceModalVisible" :wall-detail="tvWallDetail" :device-index="deviceIndex" :video-index="videoIndex" @confirm="onDeviceModalConfirm" />
    <WallUpdate v-model:open="wallUpdateModalVisible" :wall-detail="toUpdateWallDetail" @confirm="onTvWallModalConfirm" />
  </page-container>
</template>

<style lang="scss" scoped></style>

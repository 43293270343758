import { map, reduce } from 'lodash-es'

/**
 * 将本地显示电视墙改为电视墙提交信息
 */
export function translateToTvWallDetail(localTvWallDetail: LocalTvWallDetail): TvWallDetail {
  const { containerMax, userId, tvWallId, videos, wallName } = localTvWallDetail
  const nextVideos = reduce(map(videos, video => (video)), (pre, cur) => ([...pre, ...cur]), [] as TvVideo[])

  return {
    containerMax,
    userId,
    tvWallId,
    wallName,
    videos: nextVideos,
  }
}

/**
 * 电视墙提交信息转换为显示信息
 */
export function translateToLocalTvWallDetail(tvWallDetail: TvWallDetail): LocalTvWallDetail {
  const { containerMax, tvWallId, userId, wallName, videos } = tvWallDetail

  const videoMap = {} as any

  videos.forEach((video) => {
    if (videoMap[video.containerIndex]) {
      videoMap[video.containerIndex].push(video)
    }
    else {
      videoMap[video.containerIndex] = [video]
    }
  })

  return { containerMax, tvWallId, userId, wallName, videos: videoMap }
}

<script setup lang="ts">
import { cloneDeep, isEmpty, isNumber } from 'lodash-es'
import { translateToTvWallDetail } from './tools'
import { updateTvWallApi } from '@/api/tv-wall'

const props = defineProps(['wallDetail'])
const emit = defineEmits(['confirm', 'cancel'])
const open = defineModel<boolean>('open')
const message = useMessage()
const formRef = ref()
const localTvWallDetail = ref<LocalTvWallDetail>({ wallName: '', tvWallId: -1, userId: null, containerMax: 1, videos: {} })
const operation = ref<'update' | 'add'>('add')

const messageInfo = computed(() => operation.value === 'add' ? '添加成功' : '修改成功')

watch(() => open.value, async (newOpen) => {
  if (newOpen) {
    localTvWallDetail.value = cloneDeep(props.wallDetail)
    initOperation()
  }
})

function initOperation() {
  operation.value = isEmpty(props.wallDetail) ? 'add' : 'update'
}

function onCancel() {
  open.value = false
  reset()
}

function onConfirm() {
  formRef.value
    .validate()
    .then(submit)
    .catch((error) => {
      console.log('error', error)
    })
}

async function submit() {
  const wallId = await updateTvWallApi(translateToTvWallDetail(localTvWallDetail.value))
  if (isNumber(wallId)) {
    message.success(messageInfo.value)
    open.value = false
    reset()
    emit('confirm', wallId)
  }
}

function reset() {
  formRef.value.resetFields()
}
</script>

<template>
  <a-modal :open="open" title="电视墙" @ok="onConfirm" @cancel="onCancel">
    <a-form
      ref="formRef"
      :model="localTvWallDetail"
      name="basic"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
    >
      <a-form-item
        label="电视墙名称"
        name="wallName"
        :rules="[{ required: true, message: '请输入电视墙名称' }]"
      >
        <a-input v-model:value="localTvWallDetail.wallName" placeholder="请输入电视墙名称" />
      </a-form-item>

      <a-form-item
        label="分屏数量"
        name="containerMax"
        :rules="[{ required: true, message: '请选择分屏数量' }]"
      >
        <a-select
          v-model:value="localTvWallDetail.containerMax"
          placeholder="请选择分屏数量"
          :options="[{ label: '1个', value: 1 },
                     { label: '4个', value: 4 },
                     { label: '6个', value: 6 },
                     { label: '9个', value: 9 },
          ]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style lang="scss" scoped></style>

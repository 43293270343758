<script setup lang="ts">
import { cloneDeep, isUndefined } from 'lodash-es'
import { translateToTvWallDetail } from './tools'
import { getVideoByDeviceCodeApi, getVideoDeviceListApi } from '@/api/video'
import { updateTvWallApi } from '@/api/tv-wall'

const props = defineProps(['wallDetail', 'deviceIndex', 'videoIndex'])
const emit = defineEmits(['confirm'])
const open = defineModel<boolean>('open')
const message = useMessage()
const formRef = ref()
const deviceCodeAndDeviceNames = ref<VideoDeviceCodeAndName[]>([])
const channels = ref<Channel[]>([])
const localVideo = ref<TvVideo>({ deviceCode: '', deviceName: '', channelName: '', containerIndex: 1, secondsToNext: 60 })
const localTvWallDetail = ref<LocalTvWallDetail>({} as LocalTvWallDetail)

watch(() => open.value, async (newOpen) => {
  if (newOpen === true) {
    localTvWallDetail.value = cloneDeep(props.wallDetail)
    initVideoData()
    await getVideoDeviceOptions()
    await initSelection()
  }
})

function initVideoData() {
  if (!isUndefined(props.videoIndex)) {
    localVideo.value = localTvWallDetail.value.videos[props.deviceIndex][props.videoIndex]
  }
}

async function getVideoDeviceOptions() {
  deviceCodeAndDeviceNames.value = await getVideoDeviceListApi()
}

async function initSelection() {
  if (localVideo.value.deviceCode) {
    await onDeviceCodeChange({ deviceCode: localVideo.value.deviceCode }, false)
  }
}

function onCancel() {
  open.value = false
  reset()
}

function onConfirm() {
  formRef.value
    .validate()
    .then(async () => {
      if (!isUndefined(props.videoIndex)) {
        localTvWallDetail.value.videos[props.deviceIndex][props.videoIndex] = localVideo.value
      }
      else {
        if (localTvWallDetail.value.videos[props.deviceIndex].length) {
          localTvWallDetail.value.videos[props.deviceIndex].push(localVideo.value)
        }
        else {
          localTvWallDetail.value.videos[props.deviceIndex] = [localVideo.value]
        }
      }
      await updateTvWallApi(translateToTvWallDetail(localTvWallDetail.value))
      open.value = false
      emit('confirm', localVideo.value)
      reset()
    })
    .catch((error) => {
      console.log('error', error)
    })
}

async function onDeviceCodeChange(options: any, isAuto: boolean) {
  if (isAuto)
    localVideo.value.channelName = ''
  const videoDevice = await getVideoByDeviceCodeApi(options.deviceCode)
  if (!videoDevice.channels || videoDevice.channels.length === 0) {
    message.warning('该设备无视频通道，无法添加')
  }
  channels.value = videoDevice.channels
}

function reset() {
  formRef.value.resetFields()
  localVideo.value = { deviceCode: '', deviceName: '', channelName: '', containerIndex: 1, secondsToNext: 60 }
  channels.value = []
  deviceCodeAndDeviceNames.value = []
}
</script>

<template>
  <a-modal v-model:open="open" title="视频设备" @ok="onConfirm" @cancel="onCancel">
    <a-form
      ref="formRef"
      :model="localVideo"
      :label-col="{ span: 24 }"
      :wrapper-col="{ span: 24 }"
      autocomplete="off"
    >
      <a-form-item
        label="设备名称"
        name="deviceCode"
        :rules="[{ required: true, message: '请选择设备名称' }]"
      >
        <a-select v-model:value="localVideo.deviceCode" placeholder="请选择设备名称" :options="deviceCodeAndDeviceNames" :field-names="{ label: 'deviceName', value: 'deviceCode' }" @change="(value, options) => { onDeviceCodeChange(options, true) }" />
      </a-form-item>

      <a-form-item
        v-if="channels.length > 0"
        label="通道名称"
        name="channelName"
        :rules="[{ required: true, message: '请选择通道' }]"
      >
        <a-select v-model:value="localVideo.channelName" placeholder="请选择通道" :options="channels" :field-names="{ label: 'name', value: 'name' }" />
      </a-form-item>

      <a-form-item
        label="轮播间隔"
        name="secondsToNext"
        :rules="[{ required: true, message: '请输入轮播间隔时间' }]"
      >
        <a-input-number v-model:value="localVideo.secondsToNext" :default-value="60" :min="30" style="width:100%">
          <template #addonAfter>
            秒
          </template>
        </a-input-number>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup lang="ts">
import { DeleteOutlined, EditOutlined } from '@ant-design/icons-vue'

defineProps(['data', 'permission'])

const emit = defineEmits(['onDelete', 'onEdit'])

function onDelete() {
  emit('onDelete')
}

function onEdit() {
  emit('onEdit')
}
</script>

<template>
  <a-card style="width: 100%">
    <a-card-meta :title="data.deviceName">
      <template #description>
        <a-row>
          <a-col span="12">
            通道名称：{{ data.channelName }}
          </a-col>
          <a-col span="12">
            间隔时间：{{ data.secondsToNext }}
          </a-col>
        </a-row>
      </template>
    </a-card-meta>
    <template v-if="permission" #actions>
      <EditOutlined key="edit" @click="onEdit" />
      <a-popconfirm title="确定要删除？" ok-text="确定" cancel-text="取消" @confirm="onDelete">
        <DeleteOutlined key="ellipsis" />
      </a-popconfirm>
    </template>
  </a-card>
</template>

<style lang="scss" scoped></style>

<script setup lang="ts">
const props = withDefaults(defineProps<{ columns?: number, data?: any[], gutter?: [number, number] }>(), {
  columns: 1,
  data: () => [],
  gutter: () => [10, 10],
})

const rowData = ref<any[][]>([[]])

watch(() => props.data.length, () => {
  rowData.value = splitArray(props.data, props.columns)
}, { immediate: true })

function splitArray(arr: any[], size: number) {
  const result = []
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size))
  }

  return result
}
</script>

<template>
  <Scroll>
    <a-row :gutter="gutter">
      <template v-for="(item, index) in rowData" :key="`data${index}`">
        <a-col v-for="(innerData, innerIndex) in item" :key="`innerData${innerIndex}`" :span="24 / columns">
          <slot :data-item="innerData" :index="index * columns + innerIndex" />
        </a-col>
      </template>
    </a-row>
  </Scroll>
</template>
